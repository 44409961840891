import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Strong, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Паб та пивоварня Бургомістр
			</title>
			<meta name={"description"} content={"Насолоджуйтесь поєднанням азарту ігрового дня та пива ручної роботи у пабі-пивоварні \"Бургомістр\". Ось короткий огляд того, що ми пропонуємо."} />
			<meta property={"og:title"} content={"Меню | Паб та пивоварня Бургомістр"} />
			<meta property={"og:description"} content={"Насолоджуйтесь поєднанням азарту ігрового дня та пива ручної роботи у пабі-пивоварні \"Бургомістр\". Ось короткий огляд того, що ми пропонуємо."} />
			<meta property={"og:image"} content={"https://opamakinst.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://opamakinst.com/img/2559186-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://opamakinst.com/img/2559186-200.png"} />
			<meta name={"msapplication-TileColor"} content={"https://opamakinst.com/img/2559186-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 10px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://opamakinst.com/img/m1.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
					<Image
						src="https://opamakinst.com/img/m2.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Наше меню
					</Text>
					<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans">
						ідеальні поєднання
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						<Strong>
							Свіжі сорти пива:
						</Strong>
						{" "}Насолоджуйтесь постійно змінюваним асортиментом нашого крафтового пива, що гарантує свіжість та аромат кожного візиту.
						<br />
						<br />
						<Strong>
							Політ крафтового пива:
						</Strong>
						{" "}Дослідіть наші крафтові сорти пива, обравши один з них на свій смак, з різноманітними стилями та смаками.
						<br />
						<br />
						<Strong>
							Місцеве та імпортне пиво:
						</Strong>
						{" "}Вибирайте з широкого асортименту місцевого та імпортного пива на розлив, що ідеально доповнить ваше задоволення від перегляду спортивних подій.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						<Strong>
							Чемпіонський бургер:
						</Strong>
						{" "}Наш фірмовий бургер з вашими улюбленими начинками чудово поєднується з нашими крафтовими сортами пива.
						<br />
						<br />
						<Strong>
							Закуски до крафтового пива:
						</Strong>
						{" "}Відкрийте для себе асортимент пивних закусок, які доповнять ваше пиво, гарантуючи задоволення від кулінарного досвіду.
						<br />
						<br />
						<Strong>
							Ситна пабна класика:
						</Strong>
						{" "}Пориньте у світ класичних пабних страв, ретельно підібраних для того, щоб задовольнити ваші ігрові пристрасті.
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Ігрові розваги
						<br />
						<br />
						Інтерактивний спортивний зал: Пориньте у гру в нашому залі для фанатів, де екрани високої чіткості створюють неперевершені враження від перегляду.
						<br />
						<br />
						Ігри в перерві між матчами: Киньте виклик своїм друзям у грі в пив-понг, настільний футбол та інші класичні пабні ігри під час перерви між таймами.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://opamakinst.com/img/m3.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://opamakinst.com/img/m4.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://opamakinst.com/img/m5.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://opamakinst.com/img/m6.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://opamakinst.com/img/m7.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Це меню дає лише уявлення про виняткове поєднання спорту та крафтового пива в пабі та пивоварні "Бургомістр". Зв'яжіться з нами для отримання більш детальної інформації про дієтичні вподобання, веганські страви або будь-які медичні застереження. Ваш досвід у Бургомістр - на відстані одного візиту.
				</Text>
				<Link
					href="/contact-us"
					color="#000000"
					padding="12px 24px 12px 24px"
					text-decoration-line="initial"
					background="--color-green"
					margin="20px 0px 40px 0px"
					border-radius="8px"
					font="--lead"
				>
					Знайти Нас
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});